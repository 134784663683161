import React, { FC, useContext } from 'react';
import { Select } from 'antd';
import { ForumContext } from '../../../../contexts/Forum.context';

type CommentStatusFilterProps = {
    style?: React.CSSProperties,
}

export const CommentStatusFilter: FC <CommentStatusFilterProps> = ({
  style,
}) => {
  const { filterController } = useContext(ForumContext);

  return (
    <Select
      style={{ width: '180px', ...style }}
      placeholder="Status"
      options={[
        {
          value: 'markedAsIgnored',
          label: <span>Ignorado</span>,
        },
        {
          value: 'answered',
          label: <span>Respondido</span>,
        },
        {
          value: 'monitorRequestedByUser',
          label: <span>Aguardando monitor</span>,
        },
        {
          value: 'waitingAnswer',
          label: <span>Todos</span>,
        },
      ]}
      value={filterController.activeFilters.answerStatus}
      onChange={filterController.updateCommentStatusFilter}
    />
  );
};
