import {
  InfoCircleOutlined,
  LoadingOutlined,
  PlusOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import {
  IconLookup, IconName, IconDefinition, findIconDefinition,
} from '@fortawesome/fontawesome-svg-core';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Image, InputNumber, Row, Space, Typography, Select, Popover, Input, Button, Divider, message,
} from 'antd';
import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useBurgerItemsControls, useFindBurgerMenuItems, useFindSubgroups } from '../../api/burger-menu';
import { usePermissions } from '../../api/permissions';
import EditDataWrapper from '../../components/EditDataWrapper';
import PrivateComponent from '../../components/PrivateComponent';
import { getParam } from '../../hooks/useSearchParam';
import { IconFlutterHelper } from './IconFlutterHelper';
import { IconReactHelper } from './IconReactHelper';

const { Text } = Typography;
const { Option } = Select;

type EditBurgerMenuProps = {}

export function EditBurgerMenu(props: EditBurgerMenuProps) {
  const itemId = getParam('id');

  const [icon, setIcon] = useState('');
  const [iconForFlutter, setIconForFlutter] = useState('iconeFlutter');
  const [title, setTitle] = useState('');
  const [url, setUrl] = useState('');
  const [subgroup, setSubgroup] = useState('');
  const [order, setOrder] = useState<number>();
  const [permissions, setPermissions] = useState<string[]>([]);

  const [mutationLoading, setMutationLoading] = useState(false);

  const {
    data: permissionData,
    loading: permissionLoading,
  } = usePermissions();

  const { handleUpdateBurgerItems } = useBurgerItemsControls();

  const { data, loading, refetch } = useFindBurgerMenuItems({ _id: itemId });

  const [newSubgroup, setNewSubgroup] = useState('');
  const [subgroupList, setSubgroupList] = useState<string[]>([]);
  const { data: subgroupsData, loading: subgroupsLoading } = useFindSubgroups();

  useEffect(() => {
    setSubgroupList(subgroupsData?.findSubgroups ?? []);
  }, [subgroupsData?.findSubgroups]);

  const menuItemData = useMemo(() => {
    if (!data || loading) {
      return undefined;
    }
    return data.findBurgerMenuItemsAdmin[0];
  }, [data, loading]);

  useEffect(() => {
    if (menuItemData) {
      setTitle(menuItemData.title);
      setIcon(menuItemData.icon);
      setUrl(menuItemData.url);
      setSubgroup(menuItemData.subgroup);
      setOrder(menuItemData.order);
      setPermissions(menuItemData.permissions);
      setIconForFlutter(menuItemData.iconForFlutter);
    }
  }, [menuItemData]);

  const [updateObject, setUpdateObject] = useState({});

  const updateDisabled = useMemo(() => {
    if (!menuItemData) {
      return true;
    }
    const iconChanged = menuItemData.icon !== icon;
    const titleChanged = menuItemData.title !== title;
    const urlChanged = menuItemData.url !== url;
    const subgroupChanged = menuItemData.subgroup !== subgroup;
    const orderChanged = menuItemData.order !== order;
    const permissionsChanged = !(menuItemData.permissions.reduce((r, p) => r && permissions.includes(p), true)
      && permissions.reduce((r, p) => r && Boolean(menuItemData.permissions.includes(p)), true));
    const iconForFlutterChanged = menuItemData.iconForFlutter !== iconForFlutter;
    setUpdateObject({
      ...(iconChanged && { icon }),
      ...(titleChanged && { title }),
      ...(urlChanged && { url }),
      ...(subgroupChanged && { subgroup }),
      ...(orderChanged && { order }),
      ...(permissionsChanged && { permissions }),
      ...(iconForFlutterChanged && { iconForFlutter }),
    });
    return !(iconChanged
      || titleChanged
      || urlChanged
      || subgroupChanged
      || orderChanged
      || permissionsChanged
      || iconForFlutterChanged);
  }, [icon, menuItemData, order, permissions, subgroup, title, url, iconForFlutter]);

  const handleUpdate = useCallback(async () => {
    if (!title || !url || !subgroup) {
      message.error('Dados obrigatórios não preenchidos');
      return;
    }
    setMutationLoading(true);
    try {
      await handleUpdateBurgerItems({
        _id: [itemId],
        ...updateObject,
      });
      if (refetch) {
        await refetch({});
      }
      message.success('Dados salvos com sucesso.');
    } catch (error) {
      console.error(error);
    } finally {
      setMutationLoading(false);
    }
  }, [handleUpdateBurgerItems, itemId, refetch, subgroup, title, updateObject, url]);

  const iconLookup: IconLookup = { prefix: 'fas', iconName: icon as IconName };
  const iconDefinition: IconDefinition = findIconDefinition(iconLookup);

  return (
    <EditDataWrapper
      title="Editar item do menu de hamburguer"
      searchKey="id"
      placeholder="Buscar por id do item"
      loading={loading}
    >
      {loading && <LoadingOutlined />}
      {data && (
        <Space direction="vertical">
          <Row align="middle">
            Título:
            <Text
              style={{ marginLeft: '1rem', flex: 1 }}
              editable={{
                onChange: setTitle,
              }}
            >
              {title}
            </Text>
          </Row>
          <Row align="middle">
            Ícone:
            <Text
              style={{ marginLeft: '1rem', flex: 1 }}
              editable={{
                onChange: setIcon,
              }}
            >
              {icon}
            </Text>
            <IconReactHelper />
          </Row>
          <Row align="middle">
            Ícone Flutter:
            <Text
              style={{ marginLeft: '1rem', flex: 1 }}
              editable={{
                onChange: setIconForFlutter,
              }}
            >
              {iconForFlutter}
            </Text>
            <IconFlutterHelper />
          </Row>
          <Row align="middle">
            Url:
            <Text
              style={{ marginLeft: '1rem', flex: 1 }}
              editable={{
                onChange: setUrl,
              }}
            >
              {url}
            </Text>
          </Row>
          <Row align="middle">
            Subgrupo:
            {subgroupsLoading ? (
              <LoadingOutlined style={{ marginLeft: '1rem' }} />
            ) : (
              <Select
                style={{ width: 240, marginLeft: '1rem' }}
                placeholder="Subgrupo"
                onChange={value => setSubgroup(value)}
                defaultValue={menuItemData?.subgroup}
                dropdownRender={menu => (
                  <div>
                    {menu}
                    <PrivateComponent clearences={['admin']}>
                      <Divider style={{ margin: '4px 0' }} />
                      <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                        <Input style={{ flex: 'auto' }} value={newSubgroup} onChange={event => setNewSubgroup(event.target.value)} />
                        <Button
                          type="link"
                          onClick={() => {
                            setSubgroupList(prev => [...prev, newSubgroup]);
                            setNewSubgroup('');
                          }}
                        >
                          <PlusOutlined />
                          {' '}
                          Adicionar
                        </Button>
                      </div>
                    </PrivateComponent>
                  </div>
                )}
              >
                {subgroupList && subgroupList.map(c => <Option key={c} value={c}>{c}</Option>)}
              </Select>
            )}
          </Row>
          <Row align="middle">
            Ordem:
            <InputNumber
              style={{ marginLeft: '1rem', flex: 1 }}
              min={0}
              value={order}
              onChange={value => setOrder(value ?? 0)}
            />
          </Row>
          <Row align="middle">
            Visibilidade:
            {permissionLoading ? (
              <LoadingOutlined style={{ marginLeft: '1rem' }} />
            ) : (
              <Select
                placeholder="Visibilidade"
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                defaultValue={menuItemData?.permissions}
                onChange={value => setPermissions(value)}
              >
                {permissionData && permissionData.listPermissions.map(p => (
                  <Option
                    key={p._id}
                    value={p._id}
                    title={`${p.name} - ${p.description}`}
                  >
                    {p.name}
                  </Option>
                ))}
              </Select>
            )}
          </Row>
          <Row style={{ marginTop: '1rem' }}>
            <Text>Preview:</Text>
          </Row>
          <div style={{
            maxWidth: '256px', backgroundColor: '#eef0ef', padding: '8px',
          }}
          >
            <Divider
              orientation="left"
              style={{
                width: '100%',
                fontSize: '0.875rem',
                fontFamily: 'Nunito Sans',
                color: '#cdd3ce',
                margin: '0px',
                alignItems: 'center',
              }}
            >
              {subgroup}
            </Divider>
            <a
              href={url || '#'}
              style={{
                display: 'block',
                width: '100%',
              }}
            >
              <Space align="center" className="burger-preview" style={{ width: '100%', padding: '0.2rem 0.4rem', marginTop: '8px' }}>
                {icon && <FontAwesomeIcon style={{ fontSize: '1.2rem', color: '#8a9b8e' }} icon={iconDefinition || faCircle} color="black" />}
                <Text style={{ fontFamily: 'Nunito Sans', fontSize: '1rem' }}>{title}</Text>
              </Space>
            </a>
          </div>
        </Space>
      )}
      <Row style={{ marginTop: '1rem' }}>
        <Button
          type="primary"
          icon={<SaveOutlined />}
          onClick={handleUpdate}
          loading={mutationLoading}
          disabled={updateDisabled}
        >
          Salvar alterações
        </Button>
      </Row>
    </EditDataWrapper>
  );
}
