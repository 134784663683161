import {
  FC, useCallback, useMemo, useState,
} from 'react';
import {
  Col, Pagination, Row,
  Space,
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { ReturnButton } from '../components/ReturnButton';
import { ExamBookletObjectType, useListExamBooklets } from '../../../api/appeal';
import BookletList from './components/BookletList';
import BookletFilter from './components/BookletFilter';
import { getNameBetweenParenthesis } from './getNameBetweenParenthesis';

const ExamsManagerPage: FC = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [creatingMode, setCreatingMode] = useState(false);
  const [removeds, setRemoveds] = useState<string[]>([]);
  const [ufFilter, setUfFilter] = useState<string>();
  const [institutionFilter, setInstitutionFilter] = useState<string>();
  const [yearFilter, setYearFilter] = useState<number>();

  const pageSize = 30;

  const query = useListExamBooklets(undefined, currentPage - 1, pageSize);

  const onRefetch = useCallback(async () => {
    await query.refetch();
  }, [query]);

  const filteredData = useMemo((): ExamBookletObjectType[] | undefined => {
    //  [{
    //   _id: '001',
    //   examVersion: 'R2',
    //   institutionTarget: {
    //     institutionName: 'ENARE',
    //     uf: 'DF',
    //   },
    //   name: 'Caderno 1',
    //   totalQuestions: 100,
    //   year: 2024,
    // }, {
    //   _id: '002',
    //   examVersion: 'R2',
    //   institutionTarget: {
    //     institutionName: 'ENARE',
    //     uf: 'DF',
    //   },
    //   name: 'Caderno 1',
    //   totalQuestions: 100,
    //   year: 2024,
    // }, {
    //   _id: '002',
    //   examVersion: 'R2',
    //   institutionTarget: {
    //     institutionName: 'ENARE',
    //     uf: 'DF',
    //   },
    //   name: 'Caderno 1',
    //   totalQuestions: 100,
    //   year: 2024,
    // }, {
    //   _id: '002',
    //   examVersion: 'R2',
    //   institutionTarget: {
    //     institutionName: 'ENARE',
    //     uf: 'DF',
    //   },
    //   name: 'Caderno 1',
    //   totalQuestions: 100,
    //   year: 2024,
    // }, {
    //   _id: '002',
    //   examVersion: 'R2',
    //   institutionTarget: {
    //     institutionName: 'ENARE',
    //     uf: 'DF',
    //   },
    //   name: 'Caderno 1',
    //   totalQuestions: 100,
    //   year: 2024,
    // }, {
    //   _id: '002',
    //   examVersion: 'R2',
    //   institutionTarget: {
    //     institutionName: 'ENARE',
    //     uf: 'DF',
    //   },
    //   name: 'Caderno 1',
    //   totalQuestions: 100,
    //   year: 2024,
    // }, {
    //   _id: '002',
    //   examVersion: 'R2',
    //   institutionTarget: {
    //     institutionName: 'ENARE',
    //     uf: 'DF',
    //   },
    //   name: 'Caderno 1',
    //   totalQuestions: 100,
    //   year: 2024,
    // }, {
    //   _id: '003',
    //   examVersion: 'R2',
    //   institutionTarget: {
    //     institutionName: 'ENARE',
    //     uf: 'DF',
    //   },
    //   name: 'Caderno 2',
    //   totalQuestions: 80,
    //   year: 2024,
    // }]
    const data: ExamBookletObjectType[] = query.data?.listExamBooklets.examBooklets
      .filter(e => {
        const notRemoved = !removeds.includes(e._id);
        const byUf = ufFilter === undefined ? true : e.institutionTarget.uf === ufFilter;
        const byInstitution = institutionFilter === undefined
          ? true
          : e.institutionTarget.institutionName === getNameBetweenParenthesis(institutionFilter);
        const byYear = yearFilter === undefined ? true : e.year === yearFilter;
        return notRemoved && byUf && byInstitution && byYear;
      }) || [];

    if (data) {
      setTotal(query.data?.listExamBooklets.total || 0);
    }
    return data;
  }, [institutionFilter, query.data?.listExamBooklets, removeds, ufFilter, yearFilter]);

  const onHideCreatingMode = useCallback(() => {
    setCreatingMode(false);
  }, []);

  const onRemove = useCallback((v: string) => {
    setRemoveds(e => {
      return [...e, v];
    });
  }, []);

  return (
    <Col style={{ maxWidth: '1200px', margin: '0px auto 16px auto' }}>
      <Space style={{ marginBottom: 24 }}>
        <ReturnButton
          title="Gerenciamento de Provas"
        />
      </Space>
      <BookletFilter
        disabledAdd={creatingMode}
        onCreatingMode={() => setCreatingMode(true)}
        onInstitution={setInstitutionFilter}
        onUf={setUfFilter}
        onYear={setYearFilter}
      />
      {
        filteredData === undefined || query.loading
          ? <LoadingOutlined />
          : (
            <BookletList
              data={filteredData}
              creatingMode={creatingMode}
              onHideCreatingMode={onHideCreatingMode}
              onDelete={onRemove}
              onRefetch={onRefetch}
            />
          )
      }
      <Pagination
        style={{ margin: '16px 4px' }}
        simple
        pageSize={pageSize}
        current={currentPage}
        total={total}
        onChange={e => setCurrentPage(e)}
      />
    </Col>
  );
};

export default ExamsManagerPage;
