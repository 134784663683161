import {
  Row, Typography, Popover,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InfoCircleOutlined } from '@ant-design/icons';
import { faCircle } from '@fortawesome/free-solid-svg-icons';

const { Text, Link } = Typography;

export const IconReactHelper = () => {
  return (
    <Popover
      placement="rightTop"
      title="Ícone"
      content={(
        <div style={{ maxWidth: '300px' }}>
          <Row>
            <Text>
              Para as opções possíveis para o ícone, visite
              <Link href="https://fontawesome.com/v5.15/icons?d=gallery&p=2&s=solid" target="_blank"> este link.</Link>
            </Text>
          </Row>
          <Row style={{ marginTop: '1rem' }}>
            <Text>
              Na categoria &quot;solid&quot;, busque o ícone desejado.&nbsp;
              Ao encontrá-lo, utilize o nome no campo e cheque se o preview está com o ícone esperado.&nbsp;
            </Text>
          </Row>
          <Row style={{ marginTop: '1rem' }}>
            <Text>
              Caso o ícone não tenha sido digitado corretamente, o ícone padrão
              &nbsp;
              <FontAwesomeIcon icon={faCircle} />
              &nbsp;
              será utilizado.
            </Text>
          </Row>
        </div>
      )}
    >
      <InfoCircleOutlined />
    </Popover>
  );
};
