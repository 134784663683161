import {
  FC, Fragment, useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  Button,
  Col,
  Collapse,
  Descriptions,
  message,
  Modal,
  notification,
  Row,
  Select,
  Space,
  Spin,
  Tooltip,
  Typography,
} from 'antd';
import { PlusOutlined, QuestionCircleFilled, SaveOutlined } from '@ant-design/icons';
import { useAddAllActiveCourses, useManageUserRoles, useProfileByEmail } from '../../api/profile';
import { useCoursesMany, useCoursesManyInfo } from '../../api/courses';
import EditDataWrapper from '../../components/EditDataWrapper';
import { getParam } from '../../hooks/useSearchParam';
import { UserCourse } from './components/UserCourses';
import { AddUserCourse } from './components/AddUserCourse';
import PrivateComponent from '../../components/PrivateComponent';
import { useCreateAccount } from '../../api/create-account';

const { Text } = Typography;
const { Panel } = Collapse;

const roleMessages: Record<string, string> = {
  moderator: 'Moderadores são responsáveis por responder o conteúdo do fórum.',
  admin: 'Administradores têm acesso total ao sistema, exceto criação de usuários.',
  commentator: 'Comentaristas podem editar os conteúdos: questões, flashcards, mindmaps e apostilas.',
};

const EditUserPage: FC = () => {
  const email = getParam('email');
  const [roles, setRoles] = useState<string[]>();
  const [newUserEmail, setNewUserEmail] = useState('');
  const [newUserName, setNewUserName] = useState('');
  const [userCreationModalIsOpen, setUserCreationModalIsOpen] = useState(false);
  const [collaboratorModalIsOpen, setCollaboratorModalIsOpen] = useState(false);
  const [loadingCreateUser, setLoadingCreateUser] = useState(false);
  const [loadingCollaborator, setLoadingCollaborator] = useState(false);
  const [newUserPassword, setNewUserPassword] = useState(
    Math.random().toString(36).slice(2, 10),
  );
  const [selectedCollaboratorRole, setSelectedCollaboratorRole] = useState('');
  const [createAccountMutation] = useCreateAccount();

  const generateNewRandomPassword = useCallback(() => {
    setNewUserPassword(Math.random().toString(36).slice(2, 10));
  }, []);

  /** Queries & Mutations */
  const { handleManageRoles, loadingRoles } = useManageUserRoles();
  const { addAllActiveCoursesToProfile } = useAddAllActiveCourses();

  const {
    data: dataProfile,
    loading: queryLoadingProfile,
    refetch: refetchProfile,
  } = useProfileByEmail(email);

  const [
    coursesManyInfo,
    { data: dataCourses, loading: queryLoadingCoursesInfo },
  ] = useCoursesManyInfo();

  /** Memos */
  const userProfile = useMemo(() => {
    if (queryLoadingProfile || !dataProfile?.findProfileByEmail) {
      return undefined;
    }

    coursesManyInfo({
      variables: {
        ids: dataProfile.findProfileByEmail.courses.map(
          course => course.course,
        ),
      },
    });

    return dataProfile.findProfileByEmail;
  }, [coursesManyInfo, dataProfile?.findProfileByEmail, queryLoadingProfile]);

  const handleCreateUser = useCallback(async () => {
    setLoadingCreateUser(true);
    try {
      await createAccountMutation({
        variables: {
          email: newUserEmail,
          password: newUserPassword,
          name: newUserName,
        },
      });
      notification.info({
        message: 'E-mail cadastrado com sucesso!',
      });
    } catch (e) {
      console.error(e);
      switch ((e as { message: string }).message) {
        case 'User already exists':
          notification.info({ message: 'E-mail já em uso!' });
          break;
        case 'The email address is improperly formatted.':
          notification.info({ message: 'E-mail inválido!' });
          break;
        case 'The password must be a string with at least 6 characters.':
          notification.info({ message: 'Senha fraca! Mínimo de 6 caracteres.' });
          break;
        default:
          notification.info({ message: 'Erro ao criar usuário!' });
          break;
      }
    } finally {
      setLoadingCreateUser(false);
    }
  }, [createAccountMutation, newUserEmail, newUserPassword, newUserName]);

  const handleConvertToCollaborator = useCallback(async () => {
    if (!userProfile?._id) {
      message.error('ID de usuário não encontrado.');
      return;
    }

    if (!selectedCollaboratorRole) {
      message.error('Selecione um papel de colaborador.');
      return;
    }

    setLoadingCollaborator(true);
    try {
      await addAllActiveCoursesToProfile({
        variables: {
          profileId: userProfile._id,
        },
      });

      await handleManageRoles({
        userId: userProfile._id,
        roles: [...(userProfile?.roles || []), selectedCollaboratorRole],
      });

      refetchProfile();
      message.success('Usuário convertido em colaborador com sucesso.');
      setCollaboratorModalIsOpen(false);
    } catch (err) {
      message.error(String(err));
    } finally {
      setLoadingCollaborator(false);
    }
  }, [addAllActiveCoursesToProfile, handleManageRoles, selectedCollaboratorRole,
    userProfile?.roles, userProfile?._id, refetchProfile]);

  useEffect(() => {
    setRoles(userProfile?.roles || []);
  }, [userProfile?.roles]);

  useEffect(() => {
    if (!collaboratorModalIsOpen) {
      setSelectedCollaboratorRole('');
    }
  }, [collaboratorModalIsOpen]);

  /**
   * The value of this vauserIdg the courses assigned to the user if the user has courses assigned
   * - an array containing all courses on the database if the user has no course assigned
   * This behavior is due to backend implementation of `coursesManyInfo` query
   */
  const coursesAsignedToUserOrAllCourses = useMemo(() => {
    if (!userProfile || !dataCourses?.coursesManyInfo) {
      return undefined;
    }
    return dataCourses.coursesManyInfo;
  }, [dataCourses?.coursesManyInfo, userProfile]);

  return (
    <EditDataWrapper
      title="Consultar e gerenciar usuários"
      placeholder="Pesquisar pelo email"
      loading={queryLoadingProfile}
      searchKey="email"
      sideButton={(
        <PrivateComponent clearences={['super-admin', 'curator', 'admin']}>
          <Button
            icon={<PlusOutlined />}
            onClick={() => {
              setUserCreationModalIsOpen(true);
              setNewUserEmail('');
              setNewUserName('');
              generateNewRandomPassword();
            }}
          >
            Criar usuário
          </Button>
          {'  '}
          { userProfile && (
          <Button
            onClick={() => {
              setCollaboratorModalIsOpen(true);
              setSelectedCollaboratorRole('');
            }}
          >
            Converter em Colaborador
          </Button>
          )}
          <Modal
            visible={userCreationModalIsOpen}
            title="Criar usuário"
            onOk={() => null}
            onCancel={() => setUserCreationModalIsOpen(false)}
            footer={[
              <Button
                key="back"
                onClick={() => setUserCreationModalIsOpen(false)}
              >
                Cancelar
              </Button>,
              <Button
                key="submit"
                type="primary"
                loading={loadingCreateUser}
                onClick={handleCreateUser}
              >
                Criar
              </Button>,
            ]}
          >
            <Row gutter={20}>
              <Col>
                <Text>Nome:</Text>
              </Col>
              <Col>
                <Text
                  editable={{
                    onChange: setNewUserName,
                  }}
                  title="Nome"
                >
                  {newUserName}
                </Text>
              </Col>
              <Col>
                <Tooltip
                  title={`O nome de usuário será usado para a criação do perfil 
                  e para envio do email de boas vindas.`}
                >
                  <QuestionCircleFilled style={{ fontSize: '13px', marginRight: 10 }} />
                </Tooltip>
              </Col>
            </Row>
            <Row gutter={20}>
              <Col>
                <Text>E-mail:</Text>
              </Col>
              <Col>
                <Text
                  editable={{
                    onChange: setNewUserEmail,
                  }}
                  title="E-mail"
                >
                  {newUserEmail}
                </Text>
              </Col>
            </Row>
            <Row gutter={20}>
              <Col>
                <Text>Senha:</Text>
              </Col>
              <Col>
                <Text
                  editable={{
                    onChange: setNewUserPassword,
                  }}
                  title="Senha"
                >
                  {newUserPassword}
                </Text>
              </Col>
              <Col>
                <Tooltip
                  title="A senha será enviada automaticamente para o email do usuário."
                >
                  <QuestionCircleFilled style={{ fontSize: '13px', marginRight: 10 }} />
                </Tooltip>
              </Col>
            </Row>
            <Row gutter={20}>
              <Col>
                <Text style={{ color: 'red' }}>
                  Atenção: não feche o modal antes de salvar a senha!
                </Text>
              </Col>
            </Row>
          </Modal>
          <PrivateComponent clearences={['super-admin']} />

          <Modal
            visible={collaboratorModalIsOpen}
            title="Converter em Colaborador"
            onOk={handleConvertToCollaborator}
            onCancel={() => {
              setCollaboratorModalIsOpen(false);
              setSelectedCollaboratorRole('');
            }}
            footer={[
              <Button
                key="back"
                onClick={() => {
                  setCollaboratorModalIsOpen(false);
                  setSelectedCollaboratorRole('');
                }}
              >
                Cancelar
              </Button>,
              <Button
                key="submit"
                type="primary"
                loading={loadingCollaborator}
                onClick={handleConvertToCollaborator}
              >
                Inserir
              </Button>,
            ]}
          >
            <Row gutter={20}>
              <Col>
                <text style={{ marginRight: 16 }}> Qual será o papel do aluno? </text>
                <Select
                  style={{ width: 200 }}
                  placeholder="Selecione um papel"
                  value={selectedCollaboratorRole || undefined}
                  onChange={setSelectedCollaboratorRole}
                  options={[
                    { label: 'Moderador', value: 'moderator' },
                    { label: 'Administrador', value: 'admin' },
                    { label: 'Comentarista', value: 'commentator' },
                    { label: 'CX', value: 'curator' },
                  ]}
                />
              </Col>
            </Row>
            {selectedCollaboratorRole && (
            <Row gutter={20} style={{ marginTop: 10 }}>
              <Col span={24}>
                <Text type="secondary">{roleMessages[selectedCollaboratorRole]}</Text>
              </Col>
            </Row>
            )}
          </Modal>
        </PrivateComponent>
  )}
    >
      {queryLoadingProfile && <Spin />}
      {!queryLoadingProfile && !userProfile && email && (
        <Text>Usuário não encontrado</Text>
      )}
      {userProfile && (
        <>
          <Descriptions
            title="Usuário"
            size="small"
            column={1}
            contentStyle={{ marginBottom: '8px' }}
            labelStyle={{ fontWeight: 800, marginBottom: '8px' }}
          >
            <Descriptions.Item label="Nome">
              {userProfile.name}
            </Descriptions.Item>
            <Descriptions.Item label="Email">
              {userProfile.email}
            </Descriptions.Item>
            <Descriptions.Item label="Cursos">
              {queryLoadingCoursesInfo ? (
                <Spin size="small" />
              ) : (
                <Space direction="vertical">
                  {(
                    coursesAsignedToUserOrAllCourses
                    && coursesAsignedToUserOrAllCourses.length
                    && userProfile.courses.length
                  ) ? (
                    <Collapse>
                      {userProfile.courses.map(userCourse => {
                        const courseInfo = coursesAsignedToUserOrAllCourses.find(
                          uC => uC._id === userCourse.course,
                        );
                        const course = {
                          ...userCourse,
                          finishesAt: courseInfo?.finishesAt,
                        };
                        return (
                          <Panel
                            header={courseInfo?.title}
                            key={course.userCourse}
                          >
                            <UserCourse
                              key={course.userCourse}
                              userProfile={userProfile}
                              course={course}
                            />
                          </Panel>
                        );
                      })}
                    </Collapse>
                    ) : <Text>Usuário não tem acesso a nenhum curso</Text>}
                  <AddUserCourse user={userProfile} />
                </Space>
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Instituições objetivo">
              {userProfile.institutionTarget.map(institution => (
                <Fragment key={institution.institution + institution.uf}>
                  {institution.institution}
                  {' - '}
                  {institution.uf}
                  <br />
                </Fragment>
              ))}
            </Descriptions.Item>
            {userProfile.birthday && (
              <Descriptions.Item label="Aniversário" span={12}>
                {userProfile.birthday}
              </Descriptions.Item>
            )}
          </Descriptions>
          <PrivateComponent clearences={['super-admin']}>
            <Row>
              <Text strong style={{ marginRight: 10 }}>
                Papéis:
                {' '}
              </Text>
              <Select
                mode="multiple"
                allowClear
                style={{ minWidth: 200 }}
                placeholder="Please select"
                defaultValue={dataProfile?.findProfileByEmail.roles}
                value={roles}
                options={[
                  { label: 'Administrador', value: 'admin' },
                  { label: 'Comentarista', value: 'commentator' },
                  { label: 'Moderador', value: 'moderator' },
                  { label: 'Super administrador', value: 'super-admin' },
                  { label: 'Usuário de feature baralhos', value: 'flashcard_editor' },
                  { label: 'CX', value: 'curator' },
                ]}
                onChange={selectedRoles => {
                  const uniqueRoles = Array.from(new Set(selectedRoles));
                  setRoles(uniqueRoles);
                }}
              />
              <Button
                disabled={
                  [...(roles || [])].sort().join(',')
                  === [...(userProfile.roles || [])].sort().join(',')
                }
                icon={<SaveOutlined />}
                loading={loadingRoles}
                style={{ marginLeft: 10 }}
                onClick={async () => {
                  try {
                    await handleManageRoles({
                      userId: userProfile._id,
                      roles: roles || [],
                    });
                    refetchProfile();
                    message.success('Permissões atualizadas com sucesso.');
                  } catch (err) {
                    message.error(String(err));
                  }
                }}
              />
            </Row>
          </PrivateComponent>
        </>
      )}
    </EditDataWrapper>
  );
};

export default EditUserPage;
