import { useCallback } from 'react';
import { auth } from '../client/firebase';
import { aristoRestApi } from '../services/api';
import { QuestionsInput } from '../api/question';

type FilterType = Omit<QuestionsInput['filter'], 'classification'> & {
  classification?: string[];
  course?: string;
} | undefined;

interface QuestionsDownloadHook {
  getCSVDownloadLink: (filter?: FilterType) => Promise<string | undefined>;
}

export default function useQuestionsDownload(): QuestionsDownloadHook {
  const getCSVDownloadLink = useCallback(async (filter?: FilterType) => {
    try {
      const token = await auth.currentUser?.getIdToken();
      if (!token) {
        throw new Error('Usuário não autenticado.');
      }

      const queryParams = new URLSearchParams();

      if (filter) {
        Object.entries(filter).forEach(([key, value]) => {
          if (Array.isArray(value)) {
            value.forEach(v => queryParams.append(key, String(v)));
          } else if (value !== undefined && value !== null) {
            queryParams.append(key, value.toString());
          }
        });
      }

      const response = await aristoRestApi.get(
        `/restApi/download-questions-csv?${queryParams.toString()}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      return response?.data?.fileUrl;
    } catch (error) {
      console.error('Erro ao obter link do CSV:', error);
      throw Error('Erro ao fazer o download do CSV');
    }
  }, []);

  return { getCSVDownloadLink };
}
