import { CloseOutlined, QuestionCircleFilled, SaveOutlined } from '@ant-design/icons';
import { ApolloQueryResult } from '@apollo/client';
import {
  Alert,
  Button, Checkbox, DatePicker, InputNumber, message, Row, Select, Space, Tooltip, Typography,
} from 'antd';
import moment, { Moment } from 'moment';
import {
  FC, useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  CourseConfigInfoInput, CourseConfigInfoOutput, CourseConfigInputType, useUpdateCourseConfig,
} from '../../../api/courses';
import { getParam } from '../../../hooks/useSearchParam';

type CourseGeneralInformationType = {
  courseConfig?: CourseConfigInputType,
  refetchCourseConfig: (variables?: Partial<CourseConfigInfoInput> | undefined) => Promise<ApolloQueryResult<CourseConfigInfoOutput>>,
}

const { Text } = Typography;

export const CourseGeneralInformation: FC<CourseGeneralInformationType> = ({
  courseConfig, refetchCourseConfig,
}) => {
  const courseInitialConfig: Omit<CourseConfigInputType, 'courseId'> = useMemo(() => {
    return {
      smartReviewMax: {
        flashcards: courseConfig?.smartReviewMax?.flashcards ?? 50,
        questions: courseConfig?.smartReviewMax?.questions ?? 50,
      },
      courseStartsAt: courseConfig?.courseStartsAt ? moment(courseConfig?.courseStartsAt).toString() : undefined,
      courseFinishesAt: courseConfig?.courseFinishesAt ? moment(courseConfig?.courseFinishesAt).toString() : undefined,
      saleStartsAt: courseConfig?.saleStartsAt ? moment(courseConfig?.saleStartsAt).toString() : undefined,
      saleEndsAt: courseConfig?.saleEndsAt ? moment(courseConfig?.saleEndsAt).toString() : undefined,
      courseTrialPeriod: courseConfig?.courseTrialPeriod ?? 0,
      preCourseTrialPeriod: courseConfig?.preCourseTrialPeriod ?? 0,
      trialMode: courseConfig?.trialMode ?? null,
      preCourseStartsAt: courseConfig?.preCourseStartsAt ? moment(courseConfig?.preCourseStartsAt).toString() : undefined,
      isCourseEnabledForRegistration: courseConfig?.isCourseEnabledForRegistration ?? false,
    };
  }, [
    courseConfig?.courseStartsAt, courseConfig?.saleEndsAt,
    courseConfig?.saleStartsAt, courseConfig?.smartReviewMax?.flashcards,
    courseConfig?.smartReviewMax?.questions, courseConfig?.trialMode,
    courseConfig?.preCourseStartsAt,
    courseConfig?.courseFinishesAt,
    courseConfig?.courseTrialPeriod,
    courseConfig?.preCourseTrialPeriod,
    courseConfig?.isCourseEnabledForRegistration,
  ]);
  const [generalConfig, setGeneralConfig] = useState(courseInitialConfig);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [isCourseAvailable, setIsCourseAvailable] = useState(courseConfig?.isCourseEnabledForRegistration ?? false);
  const courseId = getParam('id');
  const handleUpdateCourseConfig = useUpdateCourseConfig();

  useEffect(() => {
    if (courseConfig) {
      setGeneralConfig(courseConfig);
      setIsCourseAvailable(courseConfig.isCourseEnabledForRegistration ?? false);
    } else {
      setGeneralConfig(courseInitialConfig);
    }
  }, [courseConfig, courseInitialConfig]);

  const isSaveDisabled = useMemo(() => {
    return JSON.stringify(generalConfig) === JSON.stringify(courseInitialConfig);
  }, [courseInitialConfig, generalConfig]);

  const handleChangeSmartReviewMaxQuestions = useCallback(e => {
    setGeneralConfig(prev => ({
      ...prev,
      smartReviewMax: {
        flashcards: prev.smartReviewMax?.flashcards ?? 50,
        questions: e ?? 0,
      },
    }));
  }, []);

  const handleChangeSmartReviewMaxFlashcards = useCallback(e => {
    setGeneralConfig(prev => ({
      ...prev,
      smartReviewMax: {
        flashcards: e ?? 0,
        questions: prev.smartReviewMax?.questions ?? 50,
      },
    }));
  }, []);

  const handleChangeCourseTrialPeriod = useCallback(e => {
    setGeneralConfig(prev => ({
      ...prev,
      courseTrialPeriod: e ?? 0,
    }));
  }, []);

  const handleChangePreCourseTrialPeriod = useCallback(e => {
    setGeneralConfig(prev => ({
      ...prev,
      preCourseTrialPeriod: e ?? 0,
    }));
  }, []);

  const handleChangeTrialMode = useCallback(e => {
    setGeneralConfig(prev => ({
      ...prev,
      trialMode: e,
    }));
  }, []);

  const handleChangePreCourseStartDate = useCallback((e?: Moment | null) => {
    setGeneralConfig(prev => ({
      ...prev,
      preCourseStartsAt: e ? e.toString() : null,
    }));
  }, []);

  const handleChangeCourseStartDate = useCallback((e?: Moment | null) => {
    setGeneralConfig(prev => ({
      ...prev,
      courseStartsAt: e ? e.toString() : null,
    }));
  }, []);

  const handleChangeCourseFinishDate = useCallback((e?: Moment | null) => {
    setGeneralConfig(prev => ({
      ...prev,
      courseFinishesAt: e ? e.toString() : null,
    }));
  }, []);

  const handleChangeSaleStartDate = useCallback((e?: Moment | null) => {
    setGeneralConfig(prev => ({
      ...prev,
      saleStartsAt: e ? e.toString() : null,
    }));
  }, []);

  const handleChangeSaleFinishDate = useCallback((e?: Moment | null) => {
    setGeneralConfig(prev => ({
      ...prev,
      saleEndsAt: e ? e.toString() : null,
    }));
  }, []);

  const handleToggleCourseAvailability = useCallback(e => {
    setIsCourseAvailable(e.target.checked);
    setGeneralConfig(prev => ({
      ...prev,
      isCourseEnabledForRegistration: e.target.checked ?? false,
    }));
  }, []);

  const handleUndo = useCallback(() => {
    setGeneralConfig(courseInitialConfig);
  }, [courseInitialConfig]);

  const handleUpdate = useCallback(async () => {
    const {
      courseStartsAt,
      courseFinishesAt,
      saleEndsAt,
      saleStartsAt,
      trialMode,
      preCourseStartsAt,
      courseTrialPeriod,
      preCourseTrialPeriod,
      smartReviewMax,
      isCourseEnabledForRegistration,
    } = generalConfig;
    if (!courseConfig) {
      return;
    }
    setUpdateLoading(true);
    try {
      await handleUpdateCourseConfig({
        isCourseEnabledForRegistration,
        courseId,
        courseStartsAt,
        courseFinishesAt,
        preCourseStartsAt,
        saleEndsAt,
        saleStartsAt,
        trialMode,
        courseTrialPeriod: courseTrialPeriod ?? 0,
        preCourseTrialPeriod: preCourseTrialPeriod ?? 0,
        smartReviewMax,
      });
      if (refetchCourseConfig) {
        await refetchCourseConfig({
          courseId,
        });
      }
      message.success('Configuração gerais de curso atualizada com sucesso');
    } catch (error) {
      message.error('Erro ao salvar configurações gerais de curso!');
      console.error(error);
    } finally {
      setUpdateLoading(false);
    }
  }, [courseConfig, courseId, generalConfig, handleUpdateCourseConfig, refetchCourseConfig]);

  if (!courseConfig) {
    return <></>;
  }

  return (
    <Space direction="vertical">
      <Row style={{ display: 'flex', alignItems: 'center' }}>
        <Text style={{ marginRight: 4 }}>Modo de trial</Text>
        <Tooltip
          overlayStyle={{ whiteSpace: 'pre-line' }}
          title={`Modo dinâmico: o período trial é contado a partir da data de entrada do aluno.
        Modo fixo: o período trial é contado a partir da data de início do curso.
        `}
        >
          <QuestionCircleFilled style={{ fontSize: '13px', marginRight: 10 }} />
        </Tooltip>
        <Select
          value={generalConfig.trialMode}
          style={{ width: 120 }}
          onChange={handleChangeTrialMode}
          options={[
            { value: 'FIXED', label: 'Fixo' },
            { value: 'DYNAMIC', label: 'Dinâmico' },
            { value: null, label: 'Sem trial' },
          ]}
        />
      </Row>
      <Row style={{ display: 'flex', alignItems: 'center' }}>
        <Text style={{ marginRight: 10 }}>Início das vendas</Text>
        <DatePicker
          allowClear
          format="DD/MM/YYYY \à\s HH:mm"
          showTime={{ format: 'HH:mm' }}
          value={generalConfig.saleStartsAt ? moment(new Date(generalConfig.saleStartsAt)) : null}
          onChange={handleChangeSaleStartDate}
        />
      </Row>
      <Row style={{ display: 'flex', alignItems: 'center' }}>
        <Text style={{ marginRight: 10 }}>Fim das vendas</Text>
        <DatePicker
          allowClear
          showTime={{ format: 'HH:mm' }}
          format="DD/MM/YYYY \à\s HH:mm"
          value={generalConfig.saleEndsAt ? moment(new Date(generalConfig.saleEndsAt)) : null}
          onChange={handleChangeSaleFinishDate}
        />
      </Row>
      <Row style={{ display: 'flex', alignItems: 'center' }}>
        <Text style={{ marginRight: 10 }}>Início do pré-curso</Text>
        <DatePicker
          format="DD/MM/YYYY \à\s HH:mm"
          allowClear
          showTime={{ format: 'HH:mm' }}
          value={generalConfig.preCourseStartsAt ? moment(new Date(generalConfig.preCourseStartsAt)) : null}
          onChange={handleChangePreCourseStartDate}
        />
      </Row>
      {
        generalConfig.preCourseStartsAt && generalConfig.trialMode ? (
          <Row style={{ display: 'flex', alignItems: 'center' }}>
            <Text style={{ marginRight: 10 }}>Dias de trial do pré-curso</Text>
            <InputNumber
              value={generalConfig.preCourseTrialPeriod ?? 0}
              min={0}
              onChange={handleChangePreCourseTrialPeriod}
            />
            <Text style={{ marginRight: 10, marginLeft: 10, color: '#1890ff' }}>
              {`Termina em: ${moment(new Date(generalConfig.preCourseStartsAt))
                .add(generalConfig.preCourseTrialPeriod, 'days').format('DD/MM/YYYY à\\s HH:mm')}`}
            </Text>
          </Row>
        ) : null
      }
      <Row style={{ display: 'flex', alignItems: 'center' }}>
        <Text style={{ marginRight: 10 }}>Início do curso</Text>
        <DatePicker
          format="DD/MM/YYYY \à\s HH:mm"
          allowClear={false}
          showTime={{ format: 'HH:mm' }}
          value={generalConfig.courseStartsAt ? moment(new Date(generalConfig.courseStartsAt)) : null}
          onChange={handleChangeCourseStartDate}
        />
      </Row>
      {
        generalConfig.trialMode ? (
          <Row style={{ display: 'flex', alignItems: 'center' }}>
            <Text style={{ marginRight: 10 }}>Dias de trial do curso</Text>
            <InputNumber
              value={generalConfig.courseTrialPeriod ?? 0}
              min={0}
              onChange={handleChangeCourseTrialPeriod}
            />
            <Text style={{ marginRight: 10, marginLeft: 10, color: '#1890ff' }}>
              {`Termina em: ${moment(new Date(generalConfig.courseStartsAt!))
                .add(generalConfig.courseTrialPeriod, 'days').format('DD/MM/YYYY à\\s HH:mm')}`}
            </Text>
          </Row>
        ) : null
      }
      <Row style={{ display: 'flex', alignItems: 'center' }}>
        <Text style={{ marginRight: 10 }}>Fim do curso</Text>
        <DatePicker
          format="DD/MM/YYYY \à\s HH:mm"
          allowClear
          showTime={{ format: 'HH:mm' }}
          value={generalConfig.courseFinishesAt ? moment(new Date(generalConfig.courseFinishesAt)) : null}
          onChange={handleChangeCourseFinishDate}
        />
      </Row>
      <Row style={{ display: 'flex', alignItems: 'center' }}>
        <Text style={{ marginRight: 10 }}>Questões na RI</Text>
        <InputNumber
          value={generalConfig.smartReviewMax?.questions ?? 50}
          min={0}
          onChange={handleChangeSmartReviewMaxQuestions}
        />
      </Row>
      <Row style={{ display: 'flex', alignItems: 'center' }}>
        <Text style={{ marginRight: 10 }}>Flashcards na RI</Text>
        <InputNumber
          value={generalConfig.smartReviewMax?.flashcards ?? 50}
          min={0}
          onChange={handleChangeSmartReviewMaxFlashcards}
        />
      </Row>
      <Row style={{ display: 'flex', alignItems: 'center' }}>
        <Text style={{ marginRight: 10 }}>Disponível na criação de conta?</Text>
        <Checkbox
          checked={isCourseAvailable || false}
          onChange={handleToggleCourseAvailability}
        />
      </Row>
      <Row>
        <Space className="mt-2">
          <Button
            type="ghost"
            icon={<CloseOutlined />}
            disabled={isSaveDisabled}
            onClick={handleUndo}
          >
            Desfazer
          </Button>
          <Button
            type="primary"
            icon={<SaveOutlined />}
            disabled={isSaveDisabled}
            onClick={handleUpdate}
            loading={updateLoading}
          >
            Salvar
          </Button>
        </Space>
      </Row>
    </Space>
  );
};
