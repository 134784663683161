import axios from 'axios';

const baseUrl = process.env.REACT_APP_FORUM_API_HOST;
const aristoRestUrl = process.env.REACT_APP_ARISTO_REST_API_HOST;

export const forumApi = axios.create({
  baseURL: baseUrl,
});

export const aristoRestApi = axios.create({
  baseURL: aristoRestUrl,
  timeout: 0,
});
