import {
  gql, QueryResult, useLazyQuery, useMutation, useQuery,
} from '@apollo/client';
import { message } from 'antd';
import { useCallback } from 'react';
import { DifficultyType } from './types';

export interface Comment {
  author?: string;
  profile: string;
  title?: string;
  body?: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface Rating {
  avgRating: number;
  nRating: number;
}

export interface QuestionClassification {
  detailedTreeId: string;
  leaf: string[];
}

export interface Question {
  _id: string;
  body: string;
  isOutdated?: boolean;
  isOpenToAppeal?: boolean;
  choices: string[]
  answersProportion: number[];
  correctChoice: number;
  institution: string;
  uf: string;
  year: string;
  examOrder: number;
  examType: string;
  complement: string;
  classification: QuestionClassification[];
  difficulty: DifficultyType;
  createdAt: string;
  updatedAt: string;
  comment: Comment;
  answer: number;
  rating?: Rating;
}

export interface QuestionDetailsInput {
  _id: string;
}

export interface QuestionDetailsOutput {
  questionDetails: Question;
}

const QUESTION_DETAILS = gql`
  query QuestionDetails(
    $_id: ObjectId!
  ) {
    questionDetails(
      _id: $_id
    ) {
      _id
      body
      isOutdated
      isOpenToAppeal
      choices
      answersProportion
      correctChoice
      institution
      uf
      year
      examOrder
      examType
      complement
      classification {
        detailedTreeId
        leaf
      }
      difficulty
      createdAt
      updatedAt
      comment {
        author
        profile
        title
        body
        createdAt
        updatedAt
      }
      rating {
        avgRating
        nRating
      }
      answer
    }
  }
`;

export function useQuestionDetails(_id: string): QueryResult<QuestionDetailsOutput, QuestionDetailsInput> {
  return useQuery<QuestionDetailsOutput, QuestionDetailsInput>(QUESTION_DETAILS, {
    variables: {
      _id,
    },
    skip: !_id,
    onError: error => {
      message.error(error.message);
    },
  });
}

export interface UpdateQuestionDetailsInput {
  _id: string;
  correctChoice?: number;
  classification?: QuestionClassification[];
  body?: string;
  isOutdated?: boolean;
  isOpenToAppeal?: boolean;
  choices?: string[];
}

interface UpdateQuestionDetailsOutput {
  updateQuestionDetails: boolean;
}

const UPDATE_QUESTION_DETAILS = gql`
  mutation UpdateQuestionDetails(
    $_id: ObjectId!,
    $correctChoice: Float,
    $classification: [QuestionClassificationInputType!],
    $body: String,
    $isOpenToAppeal: Boolean,
    $isOutdated: Boolean,
    $choices: [String!]
  ) {
    updateQuestionDetails(
      _id: $_id,
      correctChoice: $correctChoice,
      classification: $classification,
      body: $body,
      choices: $choices
      isOpenToAppeal: $isOpenToAppeal,
      isOutdated: $isOutdated,
    )
  }
`;

export function useUpdateQuestionDetails() {
  const [updateQuestionDetails] = useMutation<UpdateQuestionDetailsOutput, UpdateQuestionDetailsInput>(UPDATE_QUESTION_DETAILS, {
    onError: error => {
      message.error(error.message);
    },
  });
  const handleUpdateQuestionDetails = useCallback(async ({
    _id,
    correctChoice,
    classification,
    body,
    choices,
    isOutdated,
    isOpenToAppeal,
  }: UpdateQuestionDetailsInput) => {
    const res = await updateQuestionDetails({
      variables: {
        _id,
        correctChoice,
        classification,
        body,
        choices,
        isOutdated,
        isOpenToAppeal,
      },
    });
    return res.data?.updateQuestionDetails;
  }, [updateQuestionDetails]);
  return handleUpdateQuestionDetails;
}

export interface QuestionsInput {
  filter: Omit<Partial<Question>, 'classification' | 'comment'> & {
    comment?: string[];
    classification?: Partial<QuestionClassification>;
  };
  limit: number;
  skip: number;
}

export interface QuestionsOutput {
  questions: {
    questions: Question[];
    total: number;
  }
}

const QUESTIONS = gql`
  query Questions(
    $filter: QuestionFilterInputType!,
    $limit: Float!,
    $skip: Float!
  ) {
    questions(
      filter: $filter,
      limit: $limit,
      skip: $skip
    ) {
      questions {
        _id
        body
        choices
        answersProportion
        correctChoice
        isOpenToAppeal
        isOutdated
        institution
        uf
        year
        examOrder
        examType
        complement
        classification {
          detailedTreeId
          leaf
        }
        difficulty
        createdAt
        updatedAt
        comment {
          author
          profile
          title
          body
          createdAt
          updatedAt
        }
        rating {
          avgRating
          nRating
        }
        answer
      }
      total
    }
  }
`;

export function useQuestions() {
  return useLazyQuery<QuestionsOutput, QuestionsInput>(QUESTIONS, {
    onCompleted: data => {
      if (data.questions.questions.length === 0) {
        message.warn('Nenhuma questão encontrada!');
      }
    },
    fetchPolicy: 'network-only',
  });
}

export interface GetExamTypeListOutput {
  getExamTypeList: string[];
}

const GET_EXAMTYPE_LIST = gql`
  query GetExamTypeList {
    getExamTypeList
  }
`;

export function useGetExamTypeList(): QueryResult<GetExamTypeListOutput> {
  return useQuery<GetExamTypeListOutput>(GET_EXAMTYPE_LIST);
}

const LESSON_QUESTIONS = gql`
  query LessonQuestions(
    $lessonId: ObjectId!
  ) {
    lessonQuestions(lessonId: $lessonId) {
      _id
      body
      choices
      institution
      uf
      year
      examOrder
      complement
      classification {
        detailedTreeId
        leaf
      }
      difficulty
      comment {
        author
        profile
        title
        body
        createdAt
        updatedAt
      }
    }
  }
`;

interface LessonQuestionsInput {
  lessonId: string,
}

interface LessonQuestionsOutput {
  lessonQuestions: Question[],
}

export function useLessonQuestions(lessonId: string) {
  return useQuery<LessonQuestionsOutput, LessonQuestionsInput>(LESSON_QUESTIONS, {
    variables: {
      lessonId,
    },
    skip: !lessonId,
  });
}
