import { InfoCircleOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import {
  IconName, IconLookup, IconDefinition, findIconDefinition,
} from '@fortawesome/fontawesome-svg-core';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Space, Row, Popover, Select, Divider, Input, Button, InputNumber, Typography, Modal, ModalProps, message,
} from 'antd';
import { useEffect, useState } from 'react';
import { useBurgerItemsControls, useFindSubgroups } from '../../api/burger-menu';
import { usePermissions } from '../../api/permissions';
import PrivateComponent from '../../components/PrivateComponent';
import { IconReactHelper } from './IconReactHelper';
import { IconFlutterHelper } from './IconFlutterHelper';

const { Text } = Typography;
const { Option } = Select;

interface CreateBurgerItemModalProps extends ModalProps {
  onCancel?: () => void;
  afterCreate?: () => void;
}

export function CreateBurgerItemModal({
  visible, onCancel, afterCreate,
}: CreateBurgerItemModalProps) {
  const [icon, setIcon] = useState('icone');
  const [iconForFlutter, setIconForFlutter] = useState('iconeFlutter');
  const [title, setTitle] = useState('titulo');
  const [url, setUrl] = useState('url');
  const [subgroup, setSubgroup] = useState('subgrupo');
  const [subgroupList, setSubgroupList] = useState<string[]>([]);
  const [order, setOrder] = useState<number>(100);
  const [permissions, setPermissions] = useState<string[]>([]);
  const [newSubgroup, setNewSubgroup] = useState('');
  const [loadingCreate, setLoadingCreate] = useState(false);

  const iconLookup: IconLookup = { prefix: 'fas', iconName: icon as IconName };
  const iconDefinition: IconDefinition = findIconDefinition(iconLookup);

  const { handleCreateBurgerMenuItem } = useBurgerItemsControls();

  const {
    data: permissionData,
    loading: permissionLoading,
  } = usePermissions();

  const { data, loading } = useFindSubgroups();

  useEffect(() => {
    setSubgroupList(data?.findSubgroups ?? []);
  }, [data?.findSubgroups]);

  return (
    <Modal
      title="Criar item"
      visible={visible}
      confirmLoading={loadingCreate}
      onOk={async () => {
        setLoadingCreate(true);
        try {
          await handleCreateBurgerMenuItem({
            icon,
            title,
            url,
            subgroup,
            order,
            permissions,
            iconForFlutter,
          });
          setIcon('icone');
          setIconForFlutter('iconeFlutter');
          setTitle('titulo');
          setUrl('url');
          setSubgroup('subgrupo');
          setOrder(100);
          setPermissions([]);
          if (afterCreate) {
            afterCreate();
          }
          if (onCancel) {
            onCancel();
          }
        } catch (error) {
          message.error('Erro ao criar item');
        } finally {
          setLoadingCreate(false);
        }
      }}
      onCancel={onCancel}
    >
      <Space direction="vertical">
        <Row align="middle">
          Título:
          <Text
            style={{ marginLeft: '1rem', flex: 1 }}
            editable={{
              onChange: setTitle,
            }}
          >
            {title}
          </Text>
        </Row>
        <Row align="middle">
          Ícone:
          <Text
            style={{ marginLeft: '1rem', flex: 1 }}
            editable={{
              onChange: setIcon,
            }}
          >
            {icon}
          </Text>
          <IconReactHelper />
        </Row>
        <Row align="middle">
          Ícone Flutter:
          <Text
            style={{ marginLeft: '1rem', flex: 1 }}
            editable={{
              onChange: setIconForFlutter,
            }}
          >
            {iconForFlutter}
          </Text>
          <IconFlutterHelper />
        </Row>
        <Row align="middle">
          Url:
          <Text
            style={{ marginLeft: '1rem', flex: 1 }}
            editable={{
              onChange: setUrl,
            }}
          >
            {url}
          </Text>
        </Row>
        <Row align="middle">
          Subgrupo:
          {loading ? (
            <LoadingOutlined style={{ marginLeft: '1rem' }} />
          ) : (
            <Select
              style={{ width: 240, marginLeft: '1rem' }}
              placeholder="Subgrupo"
              onChange={value => setSubgroup(value)}
              defaultValue={subgroup}
              dropdownRender={menu => (
                <div>
                  {menu}
                  <PrivateComponent clearences={['admin']}>
                    <Divider style={{ margin: '4px 0' }} />
                    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                      <Input style={{ flex: 'auto' }} value={newSubgroup} onChange={event => setNewSubgroup(event.target.value)} />
                      <Button
                        type="link"
                        onClick={() => {
                          setSubgroupList(prev => [...prev, newSubgroup]);
                          setNewSubgroup('');
                        }}
                      >
                        <PlusOutlined />
                        {' '}
                        Adicionar
                      </Button>
                    </div>
                  </PrivateComponent>
                </div>
              )}
            >
              {subgroupList && subgroupList.map(c => <Option key={c} value={c}>{c}</Option>)}
            </Select>
          )}
        </Row>
        <Row align="middle">
          Ordem:
          <InputNumber
            style={{ marginLeft: '1rem', flex: 1 }}
            min={0}
            value={order}
            onChange={value => setOrder(value ?? 0)}
          />
        </Row>
        <Row align="middle">
          Visibilidade:
          {permissionLoading ? (
            <LoadingOutlined style={{ marginLeft: '1rem' }} />
          ) : (
            <Select
              placeholder="Visibilidade"
              mode="multiple"
              allowClear
              style={{ width: '100%' }}
              defaultValue={permissions}
              onChange={value => setPermissions(value)}
            >
              {permissionData && permissionData.listPermissions.map(p => (
                <Option
                  key={p._id}
                  value={p._id}
                  title={`${p.name} - ${p.description}`}
                >
                  {p.name}
                </Option>
              ))}
            </Select>
          )}
        </Row>
        <Row style={{ marginTop: '1rem' }}>
          <Text>Preview:</Text>
        </Row>
        <a
          href={url || '#'}
          style={{
            maxWidth: '300px', display: 'block', backgroundColor: '#eef0ef', padding: '0.4rem',
          }}
        >
          <Divider
            orientation="left"
            style={{
              width: '100%',
              fontSize: '0.875rem',
              fontFamily: 'Nunito Sans',
              color: '#cdd3ce',
              margin: '0px',
            }}
          >
            {subgroup}
          </Divider>
          <Space align="center" className="burger-preview" style={{ width: '100%', padding: '0.2rem 0.4rem', marginTop: '8px' }}>
            {icon && <FontAwesomeIcon style={{ fontSize: '1.2rem', color: '#8a9b8e' }} icon={iconDefinition || faCircle} color="black" />}
            <Text style={{ fontFamily: 'Nunito Sans', fontSize: '1rem' }}>{title}</Text>
          </Space>
        </a>
      </Space>
    </Modal>
  );
}
