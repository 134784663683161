import {
  Image, Row, Typography, Popover,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InfoCircleOutlined } from '@ant-design/icons';
import { faCircle } from '@fortawesome/free-solid-svg-icons';

const { Text, Link } = Typography;

export const IconFlutterHelper = () => {
  return (
    <Popover
      placement="rightTop"
      title="Ícone Flutter"
      content={(
        <div style={{ maxWidth: '300px' }}>
          <Row>
            <Text>
              Para ver a lista de ícones disponíveis, visite
              <Link href="https://api.flutter.dev/flutter/material/Icons-class.html" target="_blank">
                &nbsp;este link.
              </Link>
            </Text>
          </Row>
          <Row style={{ marginTop: '1rem' }}>
            <Text>
              Busque o ícone desejado na lista.&nbsp;
              Ao encontrá-lo, clique no nome do ícone para acessar a página com suas informações detalhadas.&nbsp;
            </Text>
          </Row>
          <Row style={{ marginTop: '1rem' }}>
            <Text>
              Copie o código do ícone e adicione-o ao campo Ícone Flutter.&nbsp;
            </Text>
          </Row>
          <Row style={{ marginTop: '1rem', textAlign: 'center' }}>
            <Image
              src="/images/helper-icon-image.png"
              alt="Imagem ilustrativa do código do ícone Flutter"
              width={250}
              height="auto"
            />
          </Row>
          <Row style={{ marginTop: '1rem' }}>
            <Text>
              Caso o código não corresponda a um ícone válido, o ícone padrão
              &nbsp;
              <FontAwesomeIcon icon={faCircle} />
              &nbsp; será utilizado.
            </Text>
          </Row>
        </div>
      )}
    >
      <InfoCircleOutlined />
    </Popover>
  );
};
