/* eslint-disable react-hooks/exhaustive-deps */
import {
  createContext, ReactNode, useEffect, useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { message } from 'antd';
import useCommentsManagement, {
  AnswerStatusType,
  ContentType,
  ForumCommentType,
  ListCommentByFilterRequest,
  PaginatedList,
  PeriodFilterType,
} from '../hooks/useCommentsManagement';

export type PaginationInfo = {
  currentPage: number;
}

type ForumContextType = {
  commentsList: PaginatedList<ForumCommentType> | undefined;
  getFiltered: () => void;
  setCommentAsReserved: (commentId: string) => Promise<void>;
  setCommentAsIgnored: (commentId: string) => Promise<void>;
  setCommentAsPrivate: (commentId: string) => Promise<void>;
  isLoading: boolean;
  isSendingData: boolean;
  filterController: {
    activeFilters: ListCommentByFilterRequest;
    updatePeriodFilter: (value: PeriodFilterType) => void;
    updateCommentStatusFilter: (value: AnswerStatusType) => void;
    updateContentFilter: (value?: ContentType) => void;
    updateBigAreaFilter: (value?: string) => void;
    updateCourseFilter: (value?: string) => void;
  },
  pageController: {
    currentPage: number,
    changePage: (value: number) => void;
  }
}

export const ForumContext = createContext({} as ForumContextType);

interface ForumContextProviderProps {
  children?: ReactNode
}

export function ForumContextProvider({
  children,
}: ForumContextProviderProps) {
  const [commentsList, setCommentsList] = useState<PaginatedList<ForumCommentType>>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSendingData, setIsSendingData] = useState<boolean>(false);

  const [activeFilters, setActiveFilters] = useState<ListCommentByFilterRequest>({
    orderBy: 'FromTheOldestToTheNewest',
    answerStatus: 'monitorRequestedByUser',
    bigArea: undefined,
    contentType: undefined,
    courseId: undefined,
    perPage: 30,
    page: 1,
  });

  const [currentPage, setCurrentPage] = useState(1);

  const navigate = useHistory();

  const {
    listForumComments,
    reserveComment,
    ignoreComment,
    privateComment,
  } = useCommentsManagement();

  const getFiltered = async () => {
    setIsLoading(true);
    setCommentsList(undefined);
    setCurrentPage(1);
    try {
      const res = await listForumComments({
        orderBy: activeFilters.orderBy,
        answerStatus: activeFilters.answerStatus,
        perPage: activeFilters.perPage,
        bigArea: activeFilters.bigArea,
        contentType: activeFilters.contentType,
        courseId: activeFilters.courseId,
        page: 1,
      });
      setActiveFilters({ ...activeFilters, page: 1 });
      setCommentsList(res);
    } catch (error) {
      message.error('Erro ao buscar lista de comentários!');
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getFiltered();
  }, []);

  const updatePeriodFilter = (value: PeriodFilterType): void => {
    setActiveFilters({ ...activeFilters, orderBy: value });
  };

  const updateCommentStatusFilter = (value: AnswerStatusType): void => {
    setActiveFilters({ ...activeFilters, answerStatus: value });
  };

  const updateContentFilter = (value?: ContentType): void => {
    setActiveFilters({ ...activeFilters, contentType: value });
  };

  const updateBigAreaFilter = (value?: string): void => {
    setActiveFilters({ ...activeFilters, bigArea: value });
  };

  const updateCourseFilter = (value?: string): void => {
    setActiveFilters({ ...activeFilters, courseId: value });
  };

  const setCommentAsIgnored = async (commentId: string): Promise<void> => {
    setIsSendingData(true);
    try {
      await ignoreComment(commentId);
      await getFiltered();

      message.success('Comentário ignorado com sucesso!');
    } catch (error) {
      message.error('Erro ao ignorar o comentário');
    }
    setIsSendingData(false);
  };

  const setCommentAsPrivate = async (commentId: string): Promise<void> => {
    if (commentsList?.items.length) {
      setIsSendingData(true);
      const commentIndex = commentsList.items.findIndex(it => it.id === commentId);

      if (commentIndex !== -1) {
        const currentComment = commentsList.items[commentIndex];
        const { status } = currentComment;

        try {
          await privateComment(commentId, !status.isPublic);
          currentComment.status.isPublic = !status.isPublic;
          const updatedList = [...commentsList.items];
          updatedList[commentIndex] = currentComment;
          setCommentsList({
            ...commentsList,
            items: updatedList,
          });
          message.success(`Comentário ${status.isPublic ? 'privado' : 'público'} com sucesso!`);
        } catch (error) {
          message.error(`Erro ao ${status.isPublic ? 'privar' : 'tornar público'} o comentário`);
        }
      } else {
        message.error('Comentário não encontrado.');
      }
      setIsSendingData(false);
    }
  };

  const setCommentAsReserved = async (commentId: string): Promise<void> => {
    setIsSendingData(true);
    try {
      const comment = commentsList?.items.filter(it => it.id === commentId);
      const area = comment && comment?.length !== 0 ? comment[0].classification[0] : '';
      await reserveComment(commentId);
      setIsSendingData(false);
      message.success('Comentário reservado com sucesso!');
      navigate.push(`/admin/responder-comentario?commentId=${commentId}&area=${area}`);
    } catch (error) {
      message.error('Erro ao reservar comentário');
      setIsSendingData(false);
    }
  };

  const changePage = async (value: number) => {
    setCurrentPage(value);
    setActiveFilters({ ...activeFilters, page: value });
    setIsLoading(true);
    setCommentsList(undefined);
    try {
      const res = await listForumComments({
        orderBy: activeFilters.orderBy,
        answerStatus: activeFilters.answerStatus,
        perPage: activeFilters.perPage,
        bigArea: activeFilters.bigArea,
        contentType: activeFilters.contentType,
        courseId: activeFilters.courseId,
        page: value,
      });
      setCommentsList(res);
    } catch (error) {
      message.error('Erro ao buscar lista de comentários!');
    }
    setIsLoading(false);
  };

  return (
    <ForumContext.Provider value={{
      commentsList,
      getFiltered,
      setCommentAsReserved,
      setCommentAsIgnored,
      setCommentAsPrivate,
      isLoading,
      isSendingData,
      filterController: {
        activeFilters,
        updatePeriodFilter,
        updateCommentStatusFilter,
        updateContentFilter,
        updateBigAreaFilter,
        updateCourseFilter,
      },
      pageController: {
        currentPage,
        changePage,
      },
    }}
    >
      {children}
    </ForumContext.Provider>
  );
}
